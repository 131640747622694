/** @define text */

.text {
  box-sizing: border-box;

  @apply text-sm leading-5 font-medium text-litlingo-body;
}

.text--extra-small {
  box-sizing: border-box;

  @apply text-xs;
}

.text--medium {
  box-sizing: border-box;

  @apply text-base;
}

.text--large {
  box-sizing: border-box;

  @apply text-lg;
}

.text--extra-large {
  box-sizing: border-box;

  @apply text-xl;
}

.text--2xextra-large {
  box-sizing: border-box;

  @apply text-2xl;
}

.text--left {
  box-sizing: border-box;

  @apply text-left;
}

.text--center {
  box-sizing: border-box;

  @apply text-center;
}

.text--semibold {
  box-sizing: border-box;

  @apply font-semibold;
}

.text--bold {
  box-sizing: border-box;

  @apply font-bold;
}

.text--italic {
  box-sizing: border-box;

  @apply italic;
}

.text--truncate {
  box-sizing: border-box;

  @apply break-words overflow-hidden whitespace-pre-wrap break-words;
}

.text--primary {
  box-sizing: border-box;

  @apply text-litlingo-primary;
}

.text--title {
  box-sizing: border-box;

  @apply text-litlingo-title font-header;
}

.text--label {
  box-sizing: border-box;

  @apply text-litlingo-label;
}

.text--placeholder {
  box-sizing: border-box;

  @apply text-litlingo-placeholder;
}

.text--lighter-0 {
  box-sizing: border-box;

  @apply text-litlingo-gray-50 font-normal;
}

.text--lighter-1 {
  box-sizing: border-box;

  @apply text-litlingo-gray-100 font-normal;
}

.text--lighter-2 {
  box-sizing: border-box;

  @apply text-litlingo-gray-200 font-normal;
}

.text--lighter-3 {
  box-sizing: border-box;

  @apply text-litlingo-gray-300 font-normal;
}

.text--lighter-4 {
  box-sizing: border-box;

  @apply text-litlingo-gray-400 font-normal;
}

.text--white {
  box-sizing: border-box;

  @apply text-litlingo-off-white;
}

.text--error {
  box-sizing: border-box;

  @apply text-red-600;
}

/** end */
