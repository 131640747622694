/* eslint-disable */
/* stylelint-disable */
@tailwind base;

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  src: local('Montserrat'), url(../assets/fonts/Montserrat.ttf) format('truetype-variations');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100 900;
  src: local('Montserrat'), url(../assets/fonts/MontserratItalic.ttf) format('truetype-variations');
}

@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 100 900;
  src: local('SourceSansPro'), url(../assets/fonts/SourceSans.ttf) format('truetype-variations');
}

@font-face {
  font-family: 'SourceSansPro';
  font-style: italic;
  font-weight: 100 900;
  src: local('SourceSansPro'),
    url(../assets/fonts/SourceSansItalic.ttf) format('truetype-variations');
}

h1 {
  @apply text-2xl;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
}
a {
  @apply text-blue-600;
}
tr:hover {
  cursor: pointer;
}
a:hover {
  cursor: pointer;
}
*:focus {
  outline: none;
}

.w-52 {
  width: 13rem;
}

.ml-28 {
  margin-left: 7rem;
}

.h-72 {
  height: 36rem;
}

.w-11 {
  width: 2.75rem;
}

.form-select {
  /* prettier-ignore */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  border-color: #d2d6dc;
  border-width: 1px;
  border-radius: 0.375rem;
  font-size: 1rem;
  line-height: 1.5;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}

.form-select-relationship {
  /* prettier-ignore */
  /* background-image: url(
    data:image/svg+xml;charset=utf-8,%3Csvg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='none'%3E%3Cpath
    d='M19 9l-7 7-7-7'
    stroke='currentColor'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'/%3E%3C/svg%3E); */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  /* color-adjust: exact; */
  background-repeat: no-repeat;
  background-color: #fff;
  border-color: #e2e8f0;
  border-width: 2px;
  border-radius: 0.375rem;
  padding: 0.25rem 0.75rem 0.25rem 0.25rem;
  font-size: 1rem;
  line-height: 1.5;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}

.login-card {
  height: 416px;
  width: 686px;
}

.login-shapes {
  bottom: 20px;
  right: 20px;
}

.error-bg-opacity {
  background-color: rgba(237, 46, 92, 0.1);
}

.half-border:after {
  content: '';
  width: 50%;
  height: 8px;
  background: #fcfcfc;
  position: absolute;
  bottom: -8px;
  right: 0;
}

.half-border:before {
  content: '';
  width: 8px;
  height: 50%;
  background: #fcfcfc;
  position: absolute;
  left: -8px;
  top: 0;
}

.filter-color {
  filter: invert(34%) sepia(9%) saturate(1138%) hue-rotate(179deg) brightness(89%) contrast(89%);
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

@tailwind components;
@import url('bem-based-styles/button.css');
@import url('bem-based-styles/table-wrapper.css');
@import url('bem-based-styles/title.css');
@import url('bem-based-styles/text.css');

.litlingo-checkbox {
  @apply cursor-pointer text-litlingo-success rounded-sm border-litlingo-gray-2;
}

.litlingo-checkbox:active {
  @apply border-litlingo-gray-6;
}

.litlingo-checkbox:hover {
  @apply border-litlingo-success;
}

.litlingo-checkbox:focus {
  @apply border-litlingo-success outline-none shadow-none;
}

.litlingo-checkbox:checked {
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M3%2012.2624L6.88618%2016.1333L17%204%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  @apply border-litlingo-gray-2;
}

.litlingo-checkbox:disabled {
  @apply cursor-default bg-litlingo-gray-1 border-litlingo-gray-1;
}

.litlingo-checkbox-negated {
  @apply text-litlingo-alert rounded-sm border-litlingo-gray-2;
}

.litlingo-checkbox-negated:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  @apply text-litlingo-alert rounded-sm border-litlingo-gray-2;
}

.checkbox-select-all-partial {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='2' viewBox='0 0 16 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1H1' stroke='%23D6D6D6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
}

.checkbox-select-all-filter:checked {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='2' viewBox='0 0 16 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1H1' stroke='%23D6D6D6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  @apply text-litlingo-white rounded-sm border-litlingo-gray-2;
}

.input {
  @apply appearance-none rounded-none relative block w-full px-4 py-2 border border-gray-300;
}
.btn {
  @apply bg-blue-500 text-white font-bold py-2 px-4 rounded;
}
.btn:hover {
  @apply bg-blue-500;
}
.btn:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.date-picker {
  width: 85px;
}

.react-datepicker__day--selected {
  background: #007656 !important;
}

.react-datepicker__day--keyboard-selected {
  background: #219653 !important;
}

@tailwind utilities;

.overview-items-container {
  @apply border my-4 rounded-md;
  min-width: 300px;
}

.gmail-auth-button {
  padding-left: 20px !important;
}
.google-button {
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  padding: 0px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}
.google-button:hover {
  cursor: pointer;
  opacity: 0.8;
}
.google-button:focus {
  outline: none;
}
.google-button:active {
  cursor: pointer;
  background-color: #eee;
  color: rgba(0, 0, 0, 0.54);
  opacity: 1;
}
.google-button__container {
  padding: 10px;
  border-radius: 2px;
}

.loader {
  border-top-color: #007656;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.min-h-wo-navbar {
  min-height: calc(100vh - 4rem);
}
.h-wo-navbar {
  height: calc(100vh - 4rem);
}
.min-h-wo-navbar-test-cases {
  min-height: calc(100vh - 8.5rem);
}
.h-wo-navbar-test-cases {
  height: calc(100vh - 8.5rem);
}
.h-vizualizer {
  height: calc(100% - 1.41rem);
}
.h-events-sidebar-content {
  height: calc(100vh - 0.168rem);
}

/*
4.00rem     = navbar height (h-16)
2.50rem     = header padding top (pt-10)
0.50rem     = header margin top (mt-2)
1.75rem     = header font size
2x1.25rem   = filters margins (my-5)
1.25rem     = table header padding top (pt-5)
2x0.75rem   = table header internal padding (py-3)

This gives in total 14rem, the remaining 4 must be
on the filters height and the table header text +
icon height
*/
.h-wo-navbar-header {
  height: calc(100vh - 18.45rem);
}

.h-wo-navbar-header-campaign-rule {
  height: calc(100vh - 10rem);
}

@keyframes bg-transition {
  0% {
    background-color: #718096;
  }
  100.0% {
    background-color: #fff;
  }
}

.bg-gray-transition {
  animation: bg-transition 3s;
}

.warning-bg-color {
  background: rgba(242, 201, 76, 0.2);
}
.warning-color {
  color: rgba(242, 201, 76, 0.2);
}

.max-h-relationship-modal {
  max-height: 350px;
}

.max-h-filter {
  max-height: 280px;
}

.max-h-select-dropdown {
  max-height: 180px;
}

.min-h-select-dropdown {
  min-height: 180px;
}

.left-50 {
  left: -50%;
}

.w-relationships-body {
  width: 520px;
}

.litlingo-gray-bg-color {
  background-color: #fdfdfd;
}

.litlingo-blue-bg-color {
  background-color: #cfe4ff;
}

.litlingo-gray-bg-color-3 {
  background-color: #4f4f4f;
}

.litlingo-gray-bg-color-4 {
  background-color: #f9f9f9f9;
}

.litlingo-red-bg-color {
  background-color: #d45353;
}

.litlingo-pink-color {
  background-color: #fff3cf;
}

.litlingo-red-color {
  color: #eb5757;
}

.litlingo-green-color {
  color: #219653;
}

.h-full\/2 {
  height: 50%;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.box-shadown {
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.25);
}

.auto-rows-layout {
  grid-auto-rows: 10px;
}

.text-title-activity-widget {
  font-size: 36px;
  line-height: normal;

  @apply font-header font-bold text-black;
}

.h-dashboard-events {
  min-height: 350px;
  height: 100%;
}

.h-widget-stacked-expanded-view {
  min-height: 1200px;
  height: 100%;
}

.h-widget-vertical-expanded-view {
  min-height: 250px;
  height: 60%;
}

.h-sankey-events {
  height: 1000px;
}

.hw-swarm-plot {
  height: 2000px;
  width: 1400px;
}

.h-top-sender-bar {
  height: 6px;
}

.h-workforce-risk-bar {
  height: 10px;
}

.h-dashboard-sentiment {
  height: 80%;
}

.disabled-dashboard-widget {
  opacity: 0.5;
  pointer-events: none;
  filter: grayscale(100%);
}

.line-height-100 {
  line-height: 100%;
}

.line-height-150 {
  line-height: 150%;
}

.litlingo-card-content {
  transition: all 0.4s ease;
}

.litlingo-collapsed {
  padding: 0;
  max-height: 0px;
  overflow: hidden;
}

.litlingo-checkbox-input:checked + .slider {
  @apply bg-litlingo-success;
}

.litlingo-checkbox-input:focus + .slider {
  box-shadow: 0 0 1px #1c5579;
}

.litlingo-checkbox-input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

.custom-checkbox {
  display: none;
}

.custom-checkbox + .custom:before {
  width: 16px;
  height: 16px;
  content: '';
  display: inline-block;
  margin: 0 8px 0 0;
  padding: 0;
  vertical-align: middle;
  @apply border border-litlingo-gray-2 rounded-full;
}

.custom-checkbox:checked + .custom:before {
  box-shadow: inset 0px 0px 0px 1px #fff;
  color: #333;
  content: '';
  text-align: center;
  border-radius: 50%;
  @apply bg-litlingo-primary border border-litlingo-primary rounded-full;
}

.litlingo-envelope-label {
  display: flex;
  flex-direction: row;
  padding: 0px 5px;

  position: static;
  left: 25.05%;
  right: 68.28%;
  top: 5%;
  bottom: 5%;
  background: #e0e0e0;
  border-radius: 4px;

  flex: none;

  align-items: center;
  margin: 0;
}

.litlingo-envelope-label-outline {
  display: flex;
  flex-direction: row;
  padding: 0px 5px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
  position: relative;
  float: left;
}

.litlingo-envelope-sort-filter {
  min-width: 78px;
  height: 30px;
  left: 1342px;
  top: 174px;

  background: #e0e0e0;
  border-radius: 4px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #4f4f4f;
}

.litlingo-envelope-new-sort-filter {
  width: 96px;
  min-height: 36px;
  left: 1342px;
  top: 174px;

  background: #eff0f6;
  border-radius: 4px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #4f4f4f;
}

.litlingo-envelope-sort-filter-options {
  color: #333333;
}

.litlingo-envelope-sort-filter-options:hover {
  color: #828282;
}

.litlingo-envelope-list-row {
  padding-top: 10px;
  padding-bottom: 5px;
}

.litlingo-envelope-comment-button-container {
  display: flex;
  flex-direction: row;
}

.litlingo-envelope-comment-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  border-radius: 4px;
}

.litlingo-envelope-add-comment-button {
  align-items: center;
  width: 46px;
  height: 30px;
  color: #333333;
  background: #f2f2f2;
  border-radius: 4px;
}

.litlingo-envelope-cancel-comment-button {
  width: 46px;
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  align-items: center;
  color: #1c5579;
}

.litlingo-envelope-comment-box {
  max-height: 210px;
}
.litlingo-envelope-message-box {
  min-height: 120px;
  max-height: 400px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  margin-top: -2px;
  color: #333333;
}

.litlingo-envelope-tab-message-box {
  padding: 0px 10px;
  height: 24px;
  left: 374px;
  top: 509px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  color: #333333;
  margin-right: -1px;
  background: #f2f2f2;
}

.litlingo-envelope-tab-message-box:focus {
  outline: none;
}

.litlingo-envelope-tab-message-box-selected {
  border-bottom: 1px solid #fff;
  background: #fff;
}

.litlingo-envelope-campaign-box {
  border: 1px solid #e0e0e0;
  border-radius: 4px 4px 4px 4px;
}

.bg-litlingo-green {
  background-color: #219653;
}

.slider {
  background-color: #ccc;
}

.w50 {
  width: 50% !important;
}

/* Keep looking for unused styles from here */

.slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 4px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch {
  width: 50px;
  height: 30px;
}

.litlingo-flex-break-words {
  white-space: break-spaces;
  overflow: hidden;
  overflow-wrap: break-word;
}

.disabled-button {
  background-color: #bdbdbd;
  color: #f2f2f2;
  opacity: 0.5;
  pointer-events: none;
}

.litlingo-label-color {
  background-color: #d8f5ff;
}

.litlingo-tag-color {
  background-color: #f4dffc;
}

.litlingo-customer-card {
  color: #333333;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}

.litlingo-customer-card-input {
  font-size: 14px;
  color: #333333;
  height: 30px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 0px 5px 10px;
}

.litlingo-gray-line {
  border: 1px solid #e0e0e0;
}

.litlingo-customer-privacy-table {
  font-size: 14px;
  text-align: center;
}

.litlingo-customer-privacy-table td {
  padding: 15px 20px 15px 20px;
  border: 1px solid #e0e0e0;
}

.litlingo-customer-save-button {
  padding-bottom: 60px;
  @apply pt-5;
}

@screen sm {
  .litlingo-customer-save-button {
    @apply px-2;
  }
}

@screen lg {
  .litlingo-customer-save-button {
    @apply px-5;
  }
}

.litlingo-customer-back-header-button {
  padding-bottom: 60px;
  @apply pt-5 flex items-center;
}

.litlingo-test-case-button {
  padding: 6px 10px 6px 10px;
  line-height: 0px;
  height: 32px;
  width: 70px;
  border: 1px solid;
  border-radius: 0px;
  box-sizing: border-box;
  align-items: center;
  text-align: center;

  @apply border-litlingo-gray-3 font-sans text-base leading-5 font-normal font-bold;
}

.litlingo-test-case-button-outline {
  @apply text-litlingo-gray-6 bg-white;
}

.litlingo-test-case-button-regular {
  @apply bg-litlingo-primary text-white;
}

.litlingo-test-case-button-first {
  border-right: transparent;
  border-bottom: transparent;
  border-radius: 4px 0px 0px 0px;
}

.litlingo-test-case-button-second {
  border-bottom: transparent;
  border-radius: 0px 4px 0px 0px;
}

.litlingo-test-case-button-third {
  border-right: transparent;
  border-radius: 0px 0px 0px 4px;
}

.litlingo-test-case-button-fourth {
  border-radius: 0px 0px 4px 0px;
}

.litlingo-test-button-rounded {
  border-radius: 4px;
}

.litlingo-annotator-badge {
  display: flex;
  flex-direction: row;
  padding: 10px;
  height: 20px;
  background: #4f4f4f;
  border-radius: 4px;
  max-width: 167px;
}

.litlingo-annotator-badge-text {
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  align-self: center;
  margin-right: 7px;
  line-height: 20px;
}

.litlingo-annotator-customer-matcher-button {
  width: 395px;
  height: 79px;
  background: #e8f1f6;
  border: 1.23466px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 12.3466px;
}

.litlingo-annotator-add-matcher-button {
  width: 120px;
  height: 28px;
  box-sizing: border-box;
  border-radius: 4px;
  @apply border border-litlingo-primary-120;
}

.litlingo-annotator-label-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  color: #333333;
}

.litlingo-annotator-delete-matcher {
  height: 28px;
  color: #eb5757;
  border: 1px solid #eb5757;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
}

.litlingo-annotator-add-button-field {
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px;
}

.litlingo-annotator-selected-matcher-bg-color {
  background: #e8f1f6;
}

.black-links a {
  color: inherit;
}

.litlingo-blue-border {
  border: 1px solid #1c5579;
}

._loading_overlay_overlay {
  background-color: white !important;
  opacity: 0.5 !important;
}

.w-80 {
  width: 20rem;
}

.rangeslider-horizontal .rangeslider__fill {
  @apply bg-litlingo-success;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 10px;
  height: 30px;
  border-radius: 0;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-horizontal .rangeslider__handle:after {
  width: 0;
  height: 0;
}

/* Scrollbars */

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  @apply bg-litlingo-gray-2;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-litlingo-gray-4;
}
.custom-scrollbar::-webkit-scrollbar-corner {
  @apply bg-litlingo-gray-3;
}

.custom-scrollbar-select-dropdown::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar-select-dropdown::-webkit-scrollbar-track {
  @apply bg-transparent;
}

.custom-scrollbar-select-dropdown::-webkit-scrollbar-thumb {
  @apply bg-litlingo-gray-2 rounded;
}

.custom-scrollbar-risk-table::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar-risk-table::-webkit-scrollbar-thumb {
  @apply bg-litlingo-gray-2;
}
.custom-scrollbar-risk-table::-webkit-scrollbar-corner {
  @apply bg-litlingo-gray-3;
}

/* This is so the ReactTags library is always on top of everything */

.react-tags {
  width: 100%;
  @apply flex items-center;
}

.react-tags__search {
  width: 100%;
}

.react-tags__suggestions {
  z-index: 1000;
  width: 100%;
}

.react-tags__suggestions > #ReactTags {
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

.react-tags__suggestions > #ReactTags::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

.react-tags__suggestions > #ReactTags::-webkit-scrollbar-track {
  @apply bg-litlingo-gray-2;
}

.react-tags__suggestions > #ReactTags::-webkit-scrollbar-thumb {
  @apply bg-litlingo-gray-4;
}
.react-tags__suggestions > #ReactTags::-webkit-scrollbar-corner {
  @apply bg-litlingo-gray-3;
}

.envelope-list-tags .react-tags {
  @apply border-0 p-0 text-left;
}

.react-tags__search-wrapper {
  @apply overflow-hidden;
}

.react-bulk-options__status-wrapper {
  @apply font-sans text-base leading-5 font-normal text-litlingo-gray-6 italic;
}

.envelope-list-tags .react-tags__search-input {
  @apply font-sans text-base leading-5 font-normal text-black;
}

.envelope-list-tags .react-tags__search-input::placeholder {
  @apply text-litlingo-gray-6 italic;
}

.envelope-page .react-tags {
  @apply border border-litlingo-gray-2 rounded;
}

.envelope-list-tags .react-tags__suggestions {
  left: -28px;
  width: 176px;
}

/* Inputs */

.form-input {
  @apply border-litlingo-gray-2;
}

.form-input-error {
  @apply border-litlingo-alert;
}

.form-input:focus {
  box-shadow: 0 0 0 3px #d9f6dc;
  @apply border-litlingo-success;
}

.form-select:focus {
  box-shadow: 0 0 0 3px #d9f6dc;
  @apply border-litlingo-success;
}

.form-textarea:focus {
  box-shadow: 0 0 0 3px #d9f6dc;
  @apply border-litlingo-success;
}

.comment-textarea::placeholder {
  text-align: right;
  @apply text-litlingo-gray-6 italic;
  transform: translateY(44px);
}

.input-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @apply w-4 h-4 rounded-full border-2 border-litlingo-gray-2;
}

.input-radio:focus {
  box-shadow: 0 0 0 3px #d9f6dc;
  @apply bg-litlingo-success border-2 border-litlingo-gray-2;
}

.input-radio:checked {
  @apply bg-litlingo-success border-2 border-litlingo-gray-2;
}

.input-radio:disabled {
  @apply cursor-default bg-litlingo-gray-1 border-litlingo-gray-1 text-litlingo-gray-3;
}

.input-radio:disabled + div {
  @apply text-litlingo-gray-3;
}

.input-radio:disabled + label {
  @apply text-litlingo-gray-3;
}

/* Sidebart filter inputs */

.sidebar-date-filter {
  transform: scale(0.9);
}

.comment-log > p,
.subject {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recipients {
  overflow: hidden;
}

.clamped-sender {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.clamped-recipients {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.description {
  overflow: hidden;
}

.clamped-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.envelope-info-container {
  @apply w-full flex flex-col p-4 bg-litlingo-gray-0 border border-litlingo-gray-2 overflow-hidden;
}

.placeholder-italic::placeholder {
  font-style: italic;
}

.text-title {
  font-size: 31px;
  line-height: 38px;

  @apply font-header font-bold text-black;
}

.text-heading-1 {
  font-size: 25px;
  line-height: 30px;

  @apply font-header font-bold text-black;
}

.text-heading-2 {
  @apply font-header text-xl leading-6 font-bold text-black;
}

.text-heading-3 {
  @apply font-header text-base leading-5 font-bold text-black;
}

.text-menu-item {
  @apply font-header text-xss leading-4 font-bold text-black;
}

.text-menu-item-navbar {
  @apply font-header text-xss leading-4 font-bold text-white;
}

.text-table-header {
  @apply font-header text-xss leading-4 font-bold text-litlingo-gray-5;
}

.text-subtitle {
  line-height: 25px;
  @apply font-sans text-xl font-normal text-litlingo-gray-6;
}

.text-body {
  @apply font-sans text-base leading-5 font-normal text-litlingo-gray-6;
}

.text-button {
  @apply font-sans text-base leading-5 font-normal text-white font-bold;
}

.text-small {
  @apply font-sans text-xss leading-4 font-normal text-litlingo-gray-6;
}

.text-legal {
  font-size: 10px;
  line-height: 13px;

  @apply font-sans font-normal text-litlingo-gray-6;
}

.to-clamp-text {
  overflow: hidden;
}

.clamped-text-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.card-shadow:hover {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

.widget-table {
  border-spacing: 0;
  @apply inline overflow-auto border border-litlingo-gray-3 border-separate;
}

.widget-table th {
  @apply px-1 text-body whitespace-no-wrap border border-t-0 border-l-0 border-litlingo-gray-3 sticky top-0;
}

.widget-table td {
  @apply px-1 text-right border border-t-0 border-l-0 border-litlingo-gray-3;
}

.widget-table th:last-child,
td:last-child {
  border-right-width: 0;
}

.widget-table tr:last-child td {
  border-bottom-width: 0;
}

.envelope-left-sidebar {
  width: 300px;
  min-width: 300px;
  filter: drop-shadow(5px 6px 18px rgba(0, 0, 0, 0.1));

  @apply z-10;
}

.button-left-sidebar {
  filter: drop-shadow(7px 3px 3px rgba(0, 0, 0, 0.05));
}

.test-right-sidebar {
  width: 357px;
  min-width: 357px;
}

.envelope-right-sidebar {
  width: 350px;
  min-width: 350px;
  box-shadow: 3px 1px 8px rgba(0, 0, 0, 0.5);
}

.user-right-sidebar {
  width: 355px;
  min-width: 355px;
  box-shadow: 3px 1px 8px rgba(0, 0, 0, 0.5);
}

.envelope-header {
  box-shadow: 3px 1px 8px rgba(0, 0, 0, 0.5);
  @apply flex flex-row justify-between items-end h-13 pr-5 pb-3 pl-6 bg-litlingo-success-light;
}

.back-to-top-button {
  min-width: 40px;
  min-height: 40px;
  filter: drop-shadow(3px 1px 8px rgba(0, 0, 0, 0.35));

  @apply absolute w-10 h-10 flex justify-center items-center bg-litlingo-primary rounded-full z-10;
}

.risk-score-right-sidebar {
  width: 350px;
  min-width: 350px;
  box-shadow: 3px 1px 8px rgba(0, 0, 0, 0.5);
}

.elastic-highlights em {
  @apply bg-litlingo-highlight;
}

/* react-day-picker */

/* general */
.rdp-root {
  --rdp-day-width: 26px;
  --rdp-day-height: 26px;
  --rdp-day_button-width: 26px;
  --rdp-day_button-height: 26px;
  --rdp-nav_button-width: 26px;
  --rdp-nav_button-height: 26px;
  --rdp-accent-color: 'black';
  margin: 0px;
  @apply text-litlingo-gray-6;
}

.rdp-nav {
  @apply h-6 w-full justify-between;
}

.rdp-button_next,
.rdp-button_previous {
  @apply;
}

.rdp-chevron {
  @apply h-5;
}

.rdp-month:first-of-type .rdp-month_caption {
  @apply pl-1 justify-center;
}

.rdp-month:last-of-type .rdp-month_caption {
  @apply pr-1 justify-center;
}

.rdp-month_caption {
  @apply h-6;
}

.rdp-caption_label {
  @apply text-heading-3 pl-1;
}

.rdp-weekday {
  @apply py-1 text-small font-bold opacity-100;
}

.rdp-day {
  @apply p-0;
}

.rdp-day_button {
  @apply text-small;
}

.rdp-selected .rdp-day_button {
  @apply border-0;
}

.rdp-selected.rdp-range_start.rdp-range_end .rdp-day_button {
  @apply bg-litlingo-primary text-white border-0;
}

.rdp-selected.rdp-range_start.rdp-range_end:focus .rdp-day_button {
  @apply bg-litlingo-primary text-white border-0;
}

.rdp-selected:not(.rdp-range_start):not(.rdp-range_end):not(.rdp-range_middle) .rdp-day_button {
  @apply bg-litlingo-primary text-white;
}

.rdp-selected:not(.rdp-range_start):not(.rdp-range_end):not(.rdp-range_middle):hover
  .rdp-day_button {
  @apply bg-litlingo-primary-140 text-white;
}

/* react-day-picker day hover */
.rdp-day:not(.rdp-selected) .rdp-day_button:hover:not([disabled]) {
  @apply bg-litlingo-primary-60 bg-opacity-50;
}

/* react-day-picker day start-end selection */
.rdp-range_start:not(.rdp-range_end) .rdp-day_button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  @apply bg-litlingo-primary text-white border-0;
}

.rdp-range_end:not(.rdp-range_start) .rdp-day_button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  @apply bg-litlingo-primary text-white border-0;
}

/* react-day-picker day middle selection */
.rdp-range_middle {
  border-radius: 0;

  @apply bg-litlingo-primary-60 bg-opacity-50 text-litlingo-gray-6;
}

.rdp-range_middle:hover {
  @apply bg-litlingo-primary-60 text-litlingo-gray-6;
}

/* react-day-picker today*/
.rdp-today:not(.rdp-outside) {
  @apply font-bold text-litlingo-primary;
}

.rdp-today:not(.rdp-outside).rdp-selected:not(.rdp-range_middle) {
  @apply text-white;
}

.rdp-today:not(.rdp-outside):not(.rdp-range_middle).rdp-selected:hover {
  @apply text-white;
}

.envelope-sidebar-collapsable:has(.collapsable-content-container > div:empty) {
  display: none;
}

.rules-compare-arrow {
  fill: none;
  stroke: currentColor;
}

.rules-compare-arrow-add {
  marker-end: url(#add-arrowhead);
}

.rules-compare-arrow-delete {
  marker-end: url(#delete-arrowhead);
}

.row_field--filter:hover > .row_field--filter__delete {
  opacity: 1;
}

.row_field--filter__delete {
  opacity: 0;
}

.chart-svg-overflow svg {
  overflow: visible;
}

.total-messages-icon:hover {
  fill: #314a48;
  cursor: pointer;
}

.flagged-messages-icon:hover {
  fill: #f4f4f4;
  cursor: pointer;
}
