@tailwind components;

@tailwind utilities;

@import 'markdown.css';

.litlingo__annotated_sentence__ellipsis {
  border-radius: 0.25rem;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.litlingo__annotated_supernode__annotations {
  font-size: 10px;
}

.litlingo__annotated_node__pos {
  font-size: 10px;
}

.litlingo__doc_viz__arrow {
  transition: 0.3s;
}

.litlingo__doc_viz__arrow__line {
  fill: none;
  stroke: currentColor;
  marker-end: url(#arrowhead);
}

.litlingo__doc_viz__arrow__text {
  fill: currentColor;
  text-anchor: middle;
}

.litlingo__doc_viz__arrows_group:hover .litlingo__doc_viz__arrow {
  opacity: 0.4;
}

.litlingo__doc_viz__arrows_group .litlingo__doc_viz__arrow:hover {
  opacity: 1;
  stroke-width: 2;
  font-weight: bold;
}

.litlingo__highlighted_sentence__tooltip {
  position: absolute;
}

.litlingo__highlighted_sentence_above__tooltip {
  top: -100%;
}

.litlingo__highlighted_sentence_below__tooltip {
  top: 100%;
}

.litlingo__outcome_card {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.litlingo__alert__warning {
  background-color: #ffe8d3;
  color: #f2994a;
}

.litlingo__alert__policy__amount {
  background-color: #333;
  color: #fff;
  border-bottom: 3px solid #A3E1C1;
}

.litlingo__modal_content__outcome_cards {
  overflow: auto;
  padding: 1px;
  max-height: 400px;
}

.litlingo__modal_content__outcome_cards > :not(:last-child) {
  margin-bottom: 10px;
}

.litlingo__sidebar_notices__content {
  overflow: auto;
  margin-top: 15px;
  padding: 1px;
  max-width: 433px;
  max-height: 400px;
}

.litlingo__sidebar_notices__content > :not(:last-child) {
  margin-bottom: 10px;
}

.litlingo__color_grey {
  color: #333333;
}

.litlingo__ellipsis_abridge {
  border-radius: 0.25rem;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.litlingo__loader_indicator {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.litlingo__color_grey_2 {
  color: #828282;
}

.litlingo__robot_center {
  text-align: center;
  margin-top: 20px;
}

.litlingo__annotated_super_node__text_xs {
  font-size: 10px;
}

.litlingo-video-wrapper iframe {
  width: 100% !important;
  height: auto !important;
}
