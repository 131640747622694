/** @define button */

.button {
  box-sizing: border-box;

  @apply inline-flex items-center px-4 py-2 border border-transparent text-base leading-5 font-medium rounded text-white transition duration-150 ease-in-out;
}

.button:focus {
  box-sizing: border-box;

  @apply outline-none;
}

.button--primary {
  box-sizing: border-box;

  @apply bg-litlingo-primary text-white font-bold;
}

.button--primary:hover {
  box-sizing: border-box;

  @apply bg-litlingo-primary-100;
}

.button--primary:focus {
  box-sizing: border-box;

  @apply bg-litlingo-primary-80;
}

.button--primary:active {
  box-sizing: border-box;

  @apply bg-litlingo-primary-140;
}

.button--primary:disabled {
  box-sizing: border-box;

  @apply bg-litlingo-gray-3 cursor-default;
}

.button--secondary-redesign {
  background-color: #fff;

  @apply w-30  bg-white border-litlingo-primary-120 font-sans text-base leading-5 font-bold text-litlingo-primary-120;
}

.button--secondary-redesign:hover {
  box-sizing: border-box;

  @apply text-litlingo-primary-100 border-litlingo-primary-100;
}

.button--secondary-redesign:focus {
  box-sizing: border-box;

  @apply text-litlingo-primary-100 border-litlingo-primary-100;
}

.button--secondary-redesign:active {
  box-sizing: border-box;

  @apply bg-litlingo-primary-60 text-litlingo-primary-100 border-litlingo-primary-100;
}

.button--secondary-redesign:disabled {
  @apply opacity-75 cursor-default border-litlingo-gray-3 text-litlingo-gray-5 bg-white;
}

.button--tertiary {
  box-sizing: border-box;

  @apply bg-litlingo-secondary-80 text-litlingo-gray-6 font-bold;
}

.button--tertiary:hover {
  box-sizing: border-box;

  @apply bg-litlingo-secondary-100;
}

.button--tertiary:focus {
  box-sizing: border-box;

  @apply bg-litlingo-secondary-60;
}

.button--tertiary:active {
  box-sizing: border-box;

  @apply bg-litlingo-secondary-140;
}

.button--tertiary:disabled {
  box-sizing: border-box;

  @apply bg-litlingo-gray-3 text-white cursor-default;
}

.button--error {
  box-sizing: border-box;

  @apply text-litlingo-alert border border-litlingo-alert font-bold;
}

.button--error:hover {
  box-sizing: border-box;

  @apply text-white;
  @apply bg-litlingo-alert;
}

.button--secondary {
  background-color: #fff;

  @apply bg-white border-litlingo-gray-3 font-sans text-base leading-5 font-normal text-litlingo-gray-5;
}

.button--secondary:hover {
  box-sizing: border-box;

  @apply text-litlingo-primary-100 border-litlingo-primary-100;
}

.button--secondary:focus {
  box-sizing: border-box;

  @apply text-litlingo-primary-100 border-litlingo-primary-100;
}

.button--secondary:active {
  box-sizing: border-box;

  @apply bg-litlingo-primary-60 text-litlingo-primary-100 border-litlingo-primary-100;
}

.button--secondary:disabled {
  @apply opacity-75 cursor-default border-litlingo-gray-3 text-litlingo-gray-5 bg-white;
}

.button--green-text {
  @apply text-litlingo-primary-120 border-litlingo-primary-120;
}

.button--blue-text {
  background-color: #fff;

  @apply text-litlingo-primary border-gray-300;
}

.button--blue-text:hover {
  box-sizing: border-box;

  @apply bg-litlingo-input-background;
}

.button--disabled {
  cursor: default;
  pointer-events: none;

  @apply bg-litlingo-gray-100;
}

.button--task-button {
  min-width: 80px;
  min-height: 85px;

  @apply flex flex-col justify-center items-center gap-2 rounded bg-white font-sans text-base leading-5 font-normal text-litlingo-primary-120;
}

.button--task-button:hover,
.button--task-button:focus {
  @apply border border-litlingo-primary-120 outline-none;
}

.button--task-button:active {
  @apply border border-litlingo-primary-60 bg-litlingo-primary-60 bg-opacity-50 text-litlingo-primary-100;
}

/** end */
